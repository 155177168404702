<template>
  <section id="conversation">
    <b-breadcrumb
      :items="breadcrumb"
    />

    <b-row>
      <b-col cols="12">
        <b-card>
          <h4 class="card-title">
            Instruções
          </h4>
          <p>Os dados serão migrados do usuário ORIGEM para o DESTINO.</p>
          <p>Ao migrar o e-mail, o usuário ORIGEM terá seu e-mail alterado(será adicionado a palavra "-COPIADO" no final do email) para não conflitar com o usuário DESTINO.</p>
        </b-card>
      </b-col>

      <b-col cols="12">
        <b-card-actions
          ref="filters"
          title="Filtros"
          class="form-filters"
          :action-close="false"
          :action-refresh="false"
          :action-collapse="true"
          @refresh="refreshStop('cardAction')"
        >
          <b-row>
            <b-col cols="5">
              <b-form-group
                label="Origem (ID do usuário)"
                label-for="userIdFrom"
              >
                <b-form-input
                  id="userIdFrom"
                  v-model="filtersValues.userIdFrom"
                  :disabled="isLoading"
                  @change="changed"
                />
              </b-form-group>
            </b-col>

            <b-col cols="5">
              <b-form-group
                label="Destino (ID do usuário)"
                label-for="userIdTo"
              >
                <b-form-input
                  id="userIdTo"
                  v-model="filtersValues.userIdTo"
                  :disabled="isLoading"
                  @change="changed"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="validate.user_from">
            <b-col cols="5">
              <p>
                <span>Nome: {{ validate.user_from.name }}</span> <br>
                <span>E-mail: {{ validate.user_from.email }}</span> <br>
                <span>Empresa: {{ validate.user_from.company_name }}</span> <br>
                <span>Tipo: {{ $t(`users.columns.type.${validate.user_from.type}`) }}</span> <br>
              </p>
            </b-col>

            <b-col cols="5">
              <p>
                <span>Nome: {{ validate.user_to.name }}</span> <br>
                <span>E-mail: {{ validate.user_to.email }}</span> <br>
                <span>Empresa: {{ validate.user_to.company_name }}</span> <br>
                <span>Tipo: {{ $t(`users.columns.type.${validate.user_from.type}`) }}</span> <br>
              </p>
            </b-col>
          </b-row>

          <b-row v-if="isValidated">
            <b-col>
              <b-form-group
                label="Dados de Cadastro"
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-form-checkbox
                  v-model="migration.name"
                  :value="1"
                  class="custom-control-primary"
                >
                  Responsável
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.company"
                  :value="1"
                  class="custom-control-primary"
                >
                  Empresa, CNPJ, Cargo
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.address"
                  :value="1"
                  class="custom-control-primary"
                >
                  Endereço
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.email"
                  :value="1"
                  class="custom-control-primary"
                >
                  E-mail
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.password"
                  :value="1"
                  class="custom-control-primary"
                >
                  Senha
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.phones"
                  :value="1"
                  class="custom-control-primary"
                >
                  Telefones
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.socials"
                  :value="1"
                  class="custom-control-primary"
                >
                  Site e Redes Sociais
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="isValidated">
            <b-col>
              <b-form-group
                label="Perfil de Busca"
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-form-checkbox
                  v-model="migration.presentation"
                  :value="1"
                  class="custom-control-primary"
                >
                  Apresentação
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.products"
                  :value="1"
                  class="custom-control-primary"
                >
                  Produtos
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.brands"
                  :value="1"
                  class="custom-control-primary"
                >
                  Marcas
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.zones"
                  :value="1"
                  class="custom-control-primary"
                >
                  Regiões
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.segments"
                  :value="1"
                  class="custom-control-primary"
                >
                  Segmentos
                </b-form-checkbox>

                <b-form-checkbox
                  v-model="migration.clients"
                  :value="1"
                  class="custom-control-primary"
                >
                  Clientes
                </b-form-checkbox>

              </b-form-group>
            </b-col>
          </b-row>

          <b-row v-if="isValidated">
            <b-col>
              <b-form-group
                label="CMS"
                class="mt-1"
              >
                <b-form-checkbox
                  v-model="migration.kanban"
                  :value="1"
                  class="custom-control-primary"
                >
                  Card do Kanban
                </b-form-checkbox>

              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col
              cols="10"
              class="form-buttons text-right"
            >
              <b-form-group
                label=""
                label-for="btn-buscar"
                style="margin-top: 20px"
              >
                <b-button
                  v-if="!isValidated"
                  id="btn-validate"
                  :disabled="isLoading"
                  variant="primary"
                  @click="validateUsers()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Validar usuários
                </b-button>

                <b-button
                  v-if="isValidated"
                  id="btn-migrate"
                  :disabled="!isValidated || isLoading"
                  variant="primary"
                  @click="migrateUser()"
                >
                  <b-spinner
                    v-if="isLoading"
                    small
                  />
                  Migrar Dados
                </b-button>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card-actions>
      </b-col>
    </b-row>

  </section>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import UserService from '@/services/userService'
import ConnectionService from '@/services/connectionService'
import { formatDateTimeDbToView } from '@/utils/helpers'
// import moment from 'moment'
import {
  BRow,
  BCard,
  BCol,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BSpinner,
  BButton,
  BBreadcrumb,
} from 'bootstrap-vue'
// import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import requestErrorMessage from '@/mixins/requestErrorMessage'

export default {
  components: {
    BCardActions,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BSpinner,
    BButton,
    BBreadcrumb,
  },

  mixins: [requestErrorMessage],

  data() {
    return {
      userService: null,
      isLoading: false,
      isValidated: false,
      isMigrated: false,
      validate: {
        user_from: null,
        user_to: null,
      },
      breadcrumb: [
        {
          text: 'Dashboard',
          to: { name: 'cms-dashboard' },
        },
        {
          text: 'Ferramentas',
          active: true,
        },
        {
          text: 'Migrar Dados de Usuário',
          active: true,
        },
      ],
      migration: {
        email: 1,
        name: 1,
        company: 1,
        address: 1,
        password: 0,
        phones: 0,
        socials: 0,
        presentation: 0,
        products: 0,
        clients: 0,
        brands: 0,
        zones: 0,
        segments: 0,
      },
      filtersInitialValues: {},
      filtersValues: {
        userIdFrom: null,
        userIdTo: null,
        type: 'all',
        status: 'all',
        sortBy: 'id',
        sortDesc: true,
      },

      pages: 1,
      currentPage: 1,
      result_fields: [],
      formatDateTimeDbToView,
    }
  },

  watch: {
    currentPage() {
      this.getUsers()
    },
  },

  created() {
    this.userService = new UserService()
    this.connectionService = new ConnectionService()
    this.filtersInitialValues = { ...this.filtersValues }

    this.result_fields = [
      { key: 'id', sortable: false, thStyle: 'width: 120px' },
      { key: 'type', sortable: false, thStyle: 'width: 170px' },
      { key: 'name', sortable: false },
      { key: 'company_name', sortable: false },
      { key: 'allEmails', sortable: false },
      { key: 'allPhones', sortable: false, thStyle: 'width: 180px' },
      { key: 'status', sortable: false },
      { key: 'actions', sortable: false, thStyle: 'width: 120px' },
    ]
  },

  methods: {
    handleCtrlEnter(e) {
      if (e.ctrlKey) {
        this.getUsers()
      }
    },

    changed() {
      this.isValidated = false
    },

    validateUsers() {
      this.validate = {
        user_from: null,
        user_to: null,
      }
      this.isValidated = false
      this.isLoading = true
      this.isMigrated = false
      this.connectionService.validateMigrate(this.filtersValues.userIdFrom, this.filtersValues.userIdTo).then(response => {
        if (response.status === 200) {
          this.validate = response.data.data.validate
          this.isValidated = true
          this.isLoading = false
        }
      }).catch(error => {
        this.isValidated = false
        this.isLoading = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erro!',
            text: this.getResponseErrorMessage(error.response.data),
            icon: 'BellIcon',
            variant: 'danger',
          },
        })
      })
    },

    migrateUser() {
      this.$swal({
        title: 'Migrar dados do usuário?',
        text: 'Esta ação não poderá ser desfeita!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, migrar!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.isLoading = true
          this.isMigrated = false

          this.userService.migrate(this.filtersValues.userIdFrom, this.filtersValues.userIdTo, { data: this.migration }).then(response => {
            if (response.status === 200) {
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Usuário migrado com sucesso!',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                  // text: `!`,
                },
              })
            }
            this.isMigrated = true
            this.isLoading = false
          }).catch(error => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Erro!',
                text: this.getResponseErrorMessage(error.response.data),
                icon: 'BellIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .header-card{
    margin-bottom: 20px;
  }

  .form-buttons button{
    margin-right: 10px;
  }
</style>
